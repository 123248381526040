import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from 'projects/evaluacion/src/app/_class/user';
import { Authentication } from 'projects/evaluacion/src/app/_class/authentication';
import { Credential } from 'projects/evaluacion/src/app/_class/credential';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private resource = `${environment.host.endpoint}/auth`;
  public credential: Credential;
  onLoginSuccess: EventEmitter<any> = new EventEmitter();
  onLoginFail: EventEmitter<any> = new EventEmitter();
  onLogout: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient) {
    const storageCredential = sessionStorage.getItem(environment.client.name);
    if (storageCredential) {
      this.credential = JSON.parse(sessionStorage.getItem(environment.client.name));
    }
  }


  Login(user: User): Observable<Credential> {
    const auth = new Authentication(user, environment.client);
    return this.http.post<Credential>(`${this.resource}/token`, auth.Body())
      .pipe(map(credential => {
        credential.expire = (credential.expire_in * 1000) + Date.now();
        this.credential = credential;
        sessionStorage.setItem(environment.client.name, JSON.stringify(credential));
        this.onLoginSuccess.emit(credential);
        return credential;
      }));
  }

  Logout() {
    this.onLogout.emit(1);
    delete sessionStorage[environment.client.name];
  }

  isExpire(): boolean {
    return !this.isValid;
  }

  Current(): Credential {
    return this.credential;
  }

  setScope(scopeChanged) {
    const objScope = JSON.parse(sessionStorage.getItem(environment.client.name));
    objScope.scope = scopeChanged;
    sessionStorage.setItem(environment.client.name, JSON.stringify(objScope));
  }


  get isValid(): boolean {
    if (this.credential) {
      return Date.now() < this.credential.expire;

    } else {
      return false;
    }
  }

  secondExpire(): number {
    if (this.credential && this.credential.expire) {
      return this.credential.expire - Date.now();
    }
    return 0;
  }

  validateToken(token): any {
    // const direccion = 'https://personal.hfgroup.cl/auth/validar';
    const direccion = 'https://chectime.com/app/auth/validar';
    // const direccion = 'ape/auth/validar';
    return this.http.get<any>(`${direccion}/${token}`, {observe: 'response'});
  }
}
