// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  app: {
    name: 'DISC',
    environment: 'DEV',
    version: '0.8'
  },
  production: false,
  user: {
    name: 'pipedev',
    pwd: '123456'
  },
  client: {
    name: 'disc',
    secret: 'X9yl)iOF5##WV&Id~rJb9}hReb,4)S'
  },
  host: {
    endpoint: '/api'
  },
  config: {
    rubrica: 3,
    empresa: 5
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
