export class User {
    constructor(
    public id ?: number,
    public username ?: string,
    public dni ?: string,
    public nombre ?: string,
    public email ?: string,
    public password ?: string,
    public cargo ?: string,
    public estado ?: string,
    public tipo ?: any,
    public scope ?: string,
    public creacion ?: any,
    public modificacion ?: any

    ) {}
}
