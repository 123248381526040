import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DiscService } from '../_services/disc.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { Evaluacion } from 'projects/evaluacion/src/app/_class/evaluacion';
import { EvaluacionPregunta } from 'projects/evaluacion/src/app/_class/evaluacion-pregunta';
import { EvaluacionService } from '../_services/evaluacion.service';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from '../_services/session.service';
import { FichaService } from '../_services/ficha.service';


@Component({
  selector: 'app-disc',
  templateUrl: './disc.component.html',
  styleUrls: ['./disc.component.scss'],
  providers: [NgbProgressbarConfig]
})

export class DiscComponent implements OnInit {

  titulo = 'Evaluación DISC';
  @ViewChild('content', { static: true }) welcomeModal: ElementRef;
  validate = this.discService.getValidate();
  numeroPregunta = 1;
  textoBoton = 'Siguiente';
  textoBotonclear = 'limpiar';
  alternativas = [];
  totalPreguntas = 0;
  evaluacion: Evaluacion;
  preguntas: EvaluacionPregunta[];
  finished = false;
  terminar = false;
  scope: {
    rut: string,
    nombre: string,
    apellido: string,
    type: string,
    url: string,
    empresa: string,
    rubrica: number,
    contrato: string
  };
  data;
  resultado = { ficha: null, scope: null, color: null, competencia: null, disc: null, nota: null };
  respuesta = {
    mas: null,
    menos: null,
    isValid: () => {
      if (this.respuesta.mas !== null && this.respuesta.menos !== null && this.respuesta.mas.descripcion !== this.respuesta.menos.descripcion) {
        return true;
      } else {
        return false;
      }
    }
  };

  constructor(
    private discService: DiscService,
    private evaluacionService: EvaluacionService,
    public modalService: NgbModal,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private fichaService: FichaService,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  async ngOnInit() {
    if (this.validate) {
      const state = this.discService.getState();
      const evaluacion = await this.evaluacionService.getEvaluacion();
      this.evaluacion = evaluacion;

      if (this.evaluacion) {
        if (state.numeroPregunta) {
          if (state.numeroPregunta == this.evaluacion.preguntas.length / 2) {
            this.terminarEvaluacion();
          } else {
            this.numeroPregunta = state.numeroPregunta + 1;
          }
        } else {
          this.OpenModalWelcome();
        }
        this.totalPreguntas = this.evaluacion.preguntas.length / 2;
        this.getPregunta();
      }
    }
  }

  getPregunta() {
    this.preguntas = _.filter(this.evaluacion.preguntas, { item_id: this.numeroPregunta });
  }

  siguientePregunta() {
    if (this.numeroPregunta !== this.totalPreguntas) {
      if (this.numeroPregunta === this.totalPreguntas - 1) {
        this.textoBoton = 'Terminar';
        this.terminar = true;
      }
      this.discService.addRespuesta(this.numeroPregunta, [
        { alt_id: this.respuesta.mas.id, creado: new Date().toISOString().slice(0, 19).replace('T', ' ') }, { alt_id: this.respuesta.menos.id, creado: new Date().toISOString().slice(0, 19).replace('T', ' ') }]);
      this.respuesta.mas = null;
      this.respuesta.menos = null;
      this.numeroPregunta++;
      this.getPregunta();
    } else {
      this.discService.addRespuesta(this.numeroPregunta, [{ alt_id: this.respuesta.mas.id, creado: new Date().toISOString().slice(0, 19).replace('T', ' ') }, { alt_id: this.respuesta.menos.id, creado: new Date().toISOString().slice(0, 19).replace('T', ' ') }]);
      this.terminarEvaluacion();
    }
  }

  async terminarEvaluacion() {
    this.scope = JSON.parse(localStorage.getItem('scope'));
    const formData = {
      ficha: {
        rub_id: this.scope.rubrica,
        emp_id: environment.config.empresa,
        creado: new Date().toISOString().slice(0, 19).replace('T', ' ')
      },
      fichaRespuestas: this.discService.getRespuesta(),
      trabajador: {
        pais_id: 'CL',
        dni: this.scope.rut,
        nombre: this.scope.nombre,
        apellido: this.scope.apellido,
      },
      // trabajadorParams : {
      //   data: {
      //     perfil : {
      //       perfil : this.scope.perfil
      //     }
      //   }
      // },
      fichaParams: {
        data: {
          competencia: {
            type: this.scope.type,
            empresa: this.scope.empresa
          },
          scope: this.scope
        }
      },

    };
    try {
      if (this.terminar) {
        this.finished = true;
      }
      const ficha = await this.evaluacionService.SaveFicha(formData).toPromise();
      console.log(ficha['id']);
      const fichaView = await this.fichaService.view(ficha['id'], { expand: 'params' }).toPromise();
      const fichaParams = fichaView['params'].data;
      this.resultado = {
        color: fichaParams.resultadoSemaforo.colorCalificacionGeneral,
        competencia: fichaParams.competencia,
        disc: fichaParams.disc,
        scope: fichaParams.scope,
        nota: fichaParams.resultadoSemaforo.puntaje,
        // nota: '${(fichaParams.resultadoSemaforo.puntaje + 8)* 100}',
        ficha: fichaView['id']
      };

      const form = document.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('action', fichaParams.scope.url);
      const hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', 'data');
      hiddenField.setAttribute('value', JSON.stringify(this.resultado));
      form.appendChild(hiddenField);
      document.body.appendChild(form);
      form.submit();
      console.log(this.resultado)
      this.discService.clearState();

    } catch (error) {
      this.finished = false;
      console.log(`error ${error}`);
    }

  }

  OpenModalWelcome() {
    this.modalService.open(this.welcomeModal, { ariaLabelledBy: 'modal-basic-title', centered: true });
  }
}
